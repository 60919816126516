import React from "react"
import { Link } from "gatsby"
import { ChevronRight } from "react-feather"
import logo from "../images/logo.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Mission = () => (
  <Layout>
    <SEO title="Our mission" />
    <div className="home-container">
      <article>
        <p>
          If this works, a lot of customer service agents will be out of jobs,
          which can certainly be unforunate. Customer service is estimated to be
          a ~$350B global industry that employs > 10M people.
        </p>

        <p>
          However, it’s important to note that a lot of customer service
          actually relies on human-to-human contact that cannot be automated.
          For instance, when people go out to restaurants, they prefer being
          served by humans as opposed to robots for that social interaction
          which is a significant part of the dining experience. Same goes for
          receptionists, and stuff. It’s not purely functional but also — human.
        </p>

        <p>
          At this stage, our product is experimental. It will undoubtedly make
          mistakes, but our hope is that it will learn from them and not make
          the mistake twice while reducing the overall space of potential
          mistakes that could occur.
        </p>

        <p>
          On the bigger picture, this venture will prove or disprove things
          conclusively:
        </p>

        <ol>
          <li>
            Whether people do not mind interacting with bots for functional
            purposes (e.g. getting a specific task done or answering a specific
            question) — our hypothesis is that the answer to this is yes.
          </li>

          <li>
            Whether customer service agents that man help desks can be replaced
            — numerous attempts have been made to automate call centers and
            customer service departments. In fact, most of them are automated to
            varying degrees, particularly with the tooling that they use.
          </li>

          <li>
            More generally, whether automation can replace humans, and what jobs
            are generated as a consequence of automating a prior role that was
            occupied by humans.
          </li>
        </ol>
      </article>
    </div>
  </Layout>
)

export default Mission
